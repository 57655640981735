  
import Vue from "vue";
import Vuex from "vuex";

import vote from "./vote.module";
import branding from "./branding.module";

Vue.use(Vuex);

export default new Vuex.Store({
	modules: {
		vote,
		branding
	}
});