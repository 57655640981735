// Base API URL paths
export default {};
let local = '';
let api = '';
let vote_url = '';
let admin_url = '';
if(["localhost", "vote.atlasweb.services"].includes(window.location.hostname)){
	local = "/data/";
	api = "https://api.atlasweb.services/";
	vote_url = "https://vote.atlasweb.services/";
	admin_url = "https://manage.atlasweb.services/";
} else {
	local = "/data/";
	api = "https://api.atlasvote.com/";
	vote_url = "https://vote.atlasvote.com/";
	admin_url = "https://manage.atlasvote.com/";
}

export const LOCAL_URL = local;
export const API_URL = api;
export const VOTE_URL = vote_url;
export const ADMIN_URL = admin_url;