// List of API endpoint URLs
export default {};

import { LOCAL_URL, API_URL } from "@/config/api.config";

// Vote endpoints
export const GET_ELECTION_DETAILS_URL = API_URL+"vote/details";
export const VALIDATE_CODE_URL = API_URL+"vote/validate";
export const LIVE_BALLOTS_URL = API_URL+"vote/ballots";
export const SUBMIT_VOTE_URL = API_URL+"vote/submit";
export const SUBMIT_SINGLE_VOTE_URL = API_URL+"vote/submit_single";
export const CUSTOM_BRANDING_URL = API_URL+"vote/branding";