// Vote Mutations
export const STORE_ELECTION_DETAILS = "getElectionDetails";
export const STORE_LIVE_ELECTION_STATUS = "setLiveElectionState";
export const SET_BRANDING = "storeLocalBranding";

export const STORE_CODE = "storeCode";
export const CLEAR_CODE = "clearCode";
export const STORE_VOTER_DETAILS = "storeVoterDetails";
export const STORE_RACE_INFO = "storeRaceInfo";
export const SET_LOADING = "setLoading";

export const SET_RACE_INDEX = "setRaceIndex";
export const SET_AMENDMENT_INDEX = "setAmendmentIndex";
export const SET_SELECTION = "setSelection";
export const SET_STATE = "setState"