// Vote actions
export const ON_LOAD = "onLoad";
export const OFF_LOAD = "offLoad";
export const FETCH_BRANDING = "fetchBranding";

export const GET_ELECTION_DETAILS = "getElectionDetails";
export const VALIDATE_CODE = "validateCode";
export const SUBMIT_VOTE = "submitVote";

export const GET_LIVE_BALLOT_LIST = "getLiveBallotList";
export const SUBMIT_SINGLE_VOTE = "submitSingleVote";

export const SET_RACE = "setRace";
export const SET_AMENDMENT_RACE = "setAmendmentRace";
export const REMOVE_RACE_SELECTION = "removeRaceSelection";
export const ADD_RACE_SELECTION = "addRaceSelection";
export const CLEAR_SELECTIONS = "clearSelections";
export const SET_RANKED_SELECTION = "setRankedSelection";