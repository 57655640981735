<template>
	<router-view/>
</template>

<style lang="scss">
  @import '@/styles/app.scss';
  @import '@/styles/mobile.scss';
  @import '@/styles/tooltips.scss';
</style>

<script>
	import $ from 'jquery';

	export default {
		name: 'App',
		components: {},
		mounted(){}
	}
	
</script>