import { ApiService } from "@/common/api.service";
import {
	GET_ELECTION_DETAILS_URL,
	VALIDATE_CODE_URL,
	LIVE_BALLOTS_URL,
	SUBMIT_VOTE_URL,
	SUBMIT_SINGLE_VOTE_URL,
	CUSTOM_BRANDING_URL
} from "@/config/api.endpoints";

const VoteService = {
	get_election_details(election_id) {
		return ApiService.post(GET_ELECTION_DETAILS_URL, {
			"election_code": election_id
		});
	},
	validate_code(code) {
		return ApiService.post(VALIDATE_CODE_URL, code);
	},
	get_live_ballots(payload){
		return ApiService.post(LIVE_BALLOTS_URL, payload);
	},
	submit_votes(pkg) {
		return ApiService.post(SUBMIT_VOTE_URL, pkg);
	},
	submit_single_vote(pkg) {
		return ApiService.post(SUBMIT_SINGLE_VOTE_URL, pkg);
	},
	get_custom_branding(payload){
		return ApiService.post(CUSTOM_BRANDING_URL, payload);
	},

	// Local storage
	get_local_election(election_id){
		var now = new Date();
		var election = JSON.parse(window.localStorage.getItem("lvs-election:"+election_id));
		if(election === null || election.expiry === undefined) return null;
		if(election.expiry < now.getTime()) {
			window.localStorage.removeItem("lvs-election:"+election_id);
			return null;
		}
		return election;
	},
	store_local_election(election_id, election){
		var now = new Date();
		election.expiry = now.getTime() + 30000; //300000 for 5 minutes
		return window.localStorage.setItem("lvs-election:"+election_id, JSON.stringify(election));
	},
	wipe_local_election(election_id){
		return window.localStorage.removeItem("lvs-election:"+election_id);
	},
};

export default VoteService;