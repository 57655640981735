import VoteService from "@/common/vote.service";

import {
	FETCH_BRANDING
} from "@/store/actions.type";
import {
	SET_BRANDING
} from "@/store/mutations.type";

const state = {
	branding: {
		"brand_logo": 'https://manage.atlasvote.com/images/atlas-logo-sm.png',
		"platform_name": "AtlasVote",
		"legal_name": "Atlas Web Services",
		"platform_support": null,
		"header_color": "#CCC",
		"secondary_color": "#DC1F26",

		"voter_notify_name": 'AtlasVote',
		"voter_notify_email": 'noreply@atlasvote.com',

		"voting_endpoint_domain": "vote.atlasvote.com"
	}
};

const getters = {
	branding(state){
		return state.branding;
	}
};

const actions = {
	[FETCH_BRANDING](context, local_domain){
		// Is a default domain
		if(["localhost:8080", "vote.atlasvote.com", "vote.atlasweb.services"].includes(local_domain)){
			context.commit(SET_BRANDING, null);
			return;
		} else {
			// Or do the call
			return VoteService.get_custom_branding({
				"domain": local_domain
			})
			.then(data => {
				context.commit(SET_BRANDING, data.data);
			}).catch(error => {
				context.commit(SET_BRANDING, null);
			});
		}
	},
};

const mutations = {
	[SET_BRANDING](state, branding) {
		let DEFAULT_MODE = true;
		if(branding === null || branding === undefined){
			branding = {
				"brand_logo": 'https://manage.atlasvote.com/images/atlas-logo-sm.png',
				"platform_name": "AtlasVote",
				"legal_name": "Atlas Web Services",
				"platform_support": null,
				"header_color": "#CCC",
				"secondary_color": "#DC1F26",
		
				"voter_notify_name": 'AtlasVote',
				"voter_notify_email": 'noreply@atlasvote.com',
		
				"voting_endpoint_domain": "vote.atlasvote.com",
				"lightmode": DEFAULT_MODE
			}
		}
		state.branding = branding;
		document.title = 'Voting Portal - '+branding.platform_name;
		if(state.branding.lightmode === undefined) state.branding.lightmode = DEFAULT_MODE;
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};