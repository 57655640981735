import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  // Vote page
  {
    path: '/',
    redirect: '/vote'
  },
  {
	path: "/",
	component: () => import("@/components/Layout/Layout"),
	children: [
		{
			path: "/vote",
			name: "enter",
			component: () => import("@/views/Vote/Open"),
			props: true
		},
		{
			path: "/vote/:election_id",
			name: "vote",
			component: () => import("@/views/Vote/Vote"),
			props: true
		},
		{
			path: "/voted",
			name: "voted",
			component: () => import("@/views/Vote/Finished"),
			props: true
		},
		// Page not found!
		{
		  path: "/terms",
		  name: "terms",
		  component: () => import("@/views/Vote/Terms"),
		  props: true
		},
		// Page not found!
		{
		  path: "/404",
		  name: "404",
		  component: () => import("@/views/General/NotFound"),
		  props: true
		}
	]
  },
  {
	path: "*",
	redirect: "/vote"
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
