import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Fingerprint2 from 'fingerprintjs2'

// Get state variables
import {ON_LOAD, OFF_LOAD, FETCH_BRANDING, GET_ELECTION_DETAILS } from "@/store/actions.type";

import { ApiService } from "./common/api.service";

Vue.config.productionTip = false

// Initialize API Service vars
ApiService.init();
Vue.use(require('vue-moment'));
Vue.use(require('v-tooltip'));
Vue.use(require('v-click-outside'));
Vue.prototype.$security = Fingerprint2;

store.dispatch(OFF_LOAD);

// Things to do on every route load
router.beforeEach(function(to, from, next) {
	if(["404", "voted", "enter", "terms"].includes(to.name)) {
		next();
	} else {
		store.dispatch(ON_LOAD);
		// Otherwise, make sure we have active session
		Promise.all([
			store.dispatch(GET_ELECTION_DETAILS, { "eid": to.params.election_id })
		]).then(() => {
			store.dispatch(OFF_LOAD);
			next();
		}).catch((error) => {
			// Invalid Election, push to unknown
			console.log(error);
			store.dispatch(OFF_LOAD);
			router.replace({ name: '404', query: { date: Date.now() }, params: { error: error }});
		});
	}
});

var host_client = location.host.replace(/^(?:https?:\/\/)?(?:www\.)?/i, "").split('/')[0];
Promise.all([
	store.dispatch(FETCH_BRANDING, host_client)
]).then(() => {
	new Vue({
		router,
		store,
		render: h => h(App)
	  }).$mount('#app')
});


