import VoteService from "@/common/vote.service";

import router from "@/router";

import {
    ON_LOAD,
	OFF_LOAD,
	GET_ELECTION_DETAILS,
	VALIDATE_CODE,
	SUBMIT_VOTE,
	SET_RACE,
	SET_AMENDMENT_RACE,
	REMOVE_RACE_SELECTION, 
	ADD_RACE_SELECTION,
	CLEAR_SELECTIONS,
	SET_RANKED_SELECTION,
	GET_LIVE_BALLOT_LIST,
	SUBMIT_SINGLE_VOTE
} from "@/store/actions.type";
import {
    STORE_ELECTION_DETAILS,
	STORE_CODE,
	CLEAR_CODE,
	STORE_VOTER_DETAILS,
	STORE_RACE_INFO,
	SET_LOADING,
	SET_RACE_INDEX,
	SET_SELECTION,
	SET_STATE,
	SET_BRANDING,
	STORE_LIVE_ELECTION_STATUS,
	SET_AMENDMENT_INDEX
} from "@/store/mutations.type";
import Vue from "vue";

const state = {
	election: {},
	races: [],
	current_race: -1,
	amendment_race: -1,
	voter: {},
	votes: [],
	amendment_votes: [],
	code: '',
	status: false,
	loading: true,
	live_election_status: ''
};

const getters = {
	live_status(state){
		return state.live_election_status;
	},
	election(state) {
		return state.election;
	},
	code(state) {
		return state.code;
	},
	races(state) {
		return state.races;
	},
	voter(state){
		return state.voter;
	},
	loading(state){
		return state.loading;
	},
	current_race_index(state){
		return state.current_race;
	},
	current_amendment_index(state){
		return state.amendment_race;
	},
	current_race(state){
		if(state.amendment_race < 0){
			return state.races[state.current_race];
		} else {
			return state.races[state.current_race].amendments[state.amendment_race];
		}
	},
	current_votes(state){
		if(state.amendment_race < 0){
			return state.votes[state.current_race];
		} else {
			return state.amendment_votes[state.amendment_race];
		}
	},
	race_step(state){
		return state.current_race;
	},
	votes(state){
		return state.votes;
	},
	amendment_votes(state){
		return state.amendment_votes;
	},
	vote_state(state){
		return state.status;
	}
};

const actions = {
	[ON_LOAD](context){
		context.commit(SET_LOADING, true);
	},
	[OFF_LOAD](context){
		context.commit(SET_LOADING, false);
	},
	[GET_ELECTION_DETAILS](context, payload){
		//If force, delete cached data
		if(payload.force === undefined) payload.force = false;
		if(payload.force) {
			VoteService.wipe_local_election(payload.eid);
		}

		//Look up election data in local cache
		var election = VoteService.get_local_election(payload.eid);
		if(election !== null) {
			context.commit(STORE_ELECTION_DETAILS, election);
			context.commit(SET_BRANDING, election.branding);
			return;
		}
		// Or do the call
		return VoteService.get_election_details( payload.eid )
		.then(data => {
			VoteService.store_local_election(payload.eid, data.data);
			context.commit(STORE_ELECTION_DETAILS, data.data);
			context.commit(SET_BRANDING, data.data.branding);
			return data;
		});
	},
	[VALIDATE_CODE](context, payload){
		context.state.code = payload;

		var session = window.localStorage.getItem("v-user-session-data");
		var vconfig=null;
		try{
			var temp_d = JSON.parse(session);
			if(temp_d !== undefined && temp_d.user_id !== undefined){
				vconfig = temp_d.user_id;
			}
		} catch(e){}
		payload['v-config'] = vconfig;
		payload['election_code'] = context.getters.election.code;

		return VoteService.validate_code(payload)
		.then(data => {
			context.commit(STORE_CODE, payload.voter_code)
			context.commit(STORE_VOTER_DETAILS, data.data.voter);
			//context.commit(STORE_RACE_INFO, data.data.races);
			return data;
		});
	},
	[GET_LIVE_BALLOT_LIST](context, payload){
		var session = window.localStorage.getItem("v-user-session-data");
		var vconfig=null;
		try{
			var temp_d = JSON.parse(session);
			if(temp_d !== undefined && temp_d.user_id !== undefined){
				vconfig = temp_d.user_id;
			}
		} catch(e){}
		
		payload["election_code"] = context.getters.election.code;
		payload["voter_code"] =  context.getters.code;
		payload["v-config"] =  vconfig;
		
		return VoteService.get_live_ballots(payload)
		.then(data => {
			context.commit(STORE_RACE_INFO, data.data.races);
			context.commit(STORE_LIVE_ELECTION_STATUS, data.data.election_status);
			return data;
		});
	},
	[SET_RACE](context, race_index){
		context.commit(SET_RACE_INDEX, race_index);
		return true;
	},
	[SET_AMENDMENT_RACE](context, race_index){
		context.commit(SET_AMENDMENT_INDEX, race_index);
		return true;
	},
	[REMOVE_RACE_SELECTION](context, candidate_id){
		var temp = context.getters.current_votes;
		for( var i = 0; i < temp.length; i++){ 
			if ( temp[i] === candidate_id) {
				temp.splice(i, 1); 
			}
		 }
		context.commit(SET_SELECTION, temp);
		return true;
	}, 
	[ADD_RACE_SELECTION](context, candidate_id){
		var temp = context.getters.current_votes;
		// If selection length == max selections, remove first item
		if(context.getters.current_race.selection_count == temp.length){
			temp.shift();
		}
		
		// add this one.
		temp.push(candidate_id);
		context.commit(SET_SELECTION, temp);
		return true;
	},
	[SET_RANKED_SELECTION](context, selection){
		var temp = context.getters.current_votes;
		
		// Loop through and if selection is made elsewhere, clear old rank
		for(var i = 0;i<temp.length;i++){
			if(temp[i] == selection.candidate_id) temp[i] = '';
		}

		// Set new candidate to rank
		temp[selection.rank] = selection.candidate_id;
		
		context.commit(SET_SELECTION, temp);
		return true;
	},
	[CLEAR_SELECTIONS](context){
		var temp = context.getters.current_votes;
		if(context.getters.current_race.race_type == "ranked"){
			for(var i = 0;i<temp.length;i++){
				temp.shift();
				temp.push('');
			}
		} else {
			for(var i = temp.length;i>0;i--){
				temp.shift();
			}
		}
		context.commit(SET_SELECTION, temp);
		return true;
	},
	[SUBMIT_SINGLE_VOTE](context, security){
		var session = window.localStorage.getItem("v-user-session-data");
		var vconfig=null;
		try{
			var temp_d = JSON.parse(session);
			if(temp_d !== undefined && temp_d.user_id !== undefined){
				vconfig = temp_d.user_id;
			}
		} catch(e){}

		var current_index = context.getters.current_race_index;

		var temp = {
			"election_code": context.getters.election.code,
			"voter_code": context.getters.code, 
			"vote": {
				"race_id": context.getters.current_race.race_id,
				"votes": context.getters.current_votes
			}, 
			"security": security,
			"v-config": vconfig
		}
		return VoteService.submit_single_vote(temp)
		.then(data => {
			return data;
		});
	},
	[SUBMIT_VOTE](context, security){
		var vote_data = [];
		for(var i=0;i<context.getters.races.length;i++){
			var temp = {
				"race_id": context.getters.races[i].race_id,
				"votes": context.getters.votes[i]
			};
			vote_data.push(temp);
		}

		var session = window.localStorage.getItem("v-user-session-data");
		var vconfig=null;
		try{
			var temp_d = JSON.parse(session);
			if(temp_d !== undefined && temp_d.user_id !== undefined){
				vconfig = temp_d.user_id;
			}
		} catch(e){}

		// Now send vote array to server
		return VoteService.submit_votes({
			"election_code": context.getters.election.code,
			"voter_code": context.getters.code, 
			"votes": vote_data, 
			"security": security,
			"v-config": vconfig
		})
		.then(data => {
			return data;
		});
	}
};

const mutations = {
	[SET_LOADING](state, status){
		state.loading = status;
	},
	[SET_STATE](state, status){
		state.status = status
	},
	[STORE_LIVE_ELECTION_STATUS](state, status){
		state.live_election_status =status;
	},
	[STORE_ELECTION_DETAILS](state, election){
		state.election = election;
		state.live_election_status = election.status;
	},
	[STORE_CODE](state, code) {
		state.code = code;
	},
	[CLEAR_CODE](state) {
		state.code = '';
	},
	[STORE_VOTER_DETAILS](state, voter){
		state.voter = voter;
	},
	[SET_RACE_INDEX](state, index){
		state.current_race = index;
		
		if(index < 0) return;
		// seed race amendments
		state.amendment_votes = [];
		var race = state.races[index];
		if(race.amendments === undefined) return;

		for(var k=0;k<race.amendments.length;k++){
			if(race.amendments[k].race_type == "ranked") {
				var temp = [];
				for(var j=0;j<race.amendments[k].selection_count;j++){
					temp.push('');
				}
				state.amendment_votes[k] = temp;
			} else state.amendment_votes[k] = [];
		}
	},
	[SET_AMENDMENT_INDEX](state, index){
		state.amendment_race = index;
	},
	[SET_SELECTION](state, vote_data){
		if(state.amendment_race < 0){
			Vue.set(state.votes, state.current_race, vote_data);
		} else {
			Vue.set(state.amendment_votes, state.amendment_race, vote_data);
		}
	},
	[STORE_RACE_INFO](state, races){
		state.races = races;
		state.votes = [];
		// seed race vote results
		for(var i=0;i<races.length;i++){
			if(races[i].race_type == "ranked") {
				var temp = [];
				for(var j=0;j<races[i].selection_count;j++){
					temp.push('');
				}
				state.votes.push(temp);
			} else state.votes.push([]);
		}
	}
};

export default {
	state,
	getters,
	actions,
	mutations
};