import Vue from "vue";
import axios from "axios";
import VueAxios from "vue-axios";

import store from "@/store";

import { CLEAR_TOKEN } from "@/store/mutations.type";


export const ApiService = {
	init() {
		Vue.use(VueAxios, axios);
		Vue.prototype.$axios = axios;
		Vue.axios.defaults.baseURL = "";
		Vue.axios.defaults.headers = {
			'Custom': "CORS",
			'Accept': "*/*",
			'Content-Type': "application/json"
		};
	},

	// @TODO -> RENAME TO GET
	get(resource, params) {
		return new Promise((resolve, reject) => {
			Vue.axios.get(resource, { params })
			.then(({data}) => {
				if(!data.success) reject(data.message);
				resolve(data);
			})
			.catch(error => {
				reject("An error occured on the server.");
			});
		});
	},

	// @TODO -> RENAME TO POST
	post(resource, params, custom_config) {
		return Vue.axios.post(`${resource}`, params)
		.then(({data}) => {
			if(!data.success) throw data.message;
			return data;
		});
	}
};